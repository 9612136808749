<template>
  <div>
    <!--
      要resetFields起作用，必须配置:model和prop
      -->
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button type="primary" size="small" plain @click="handleReLoad"
        >刷新</el-button
      >
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        prop="companyName"
        label="单位"
        width="180"
      ></el-table-column>
      <!-- <el-table-column
          prop="companyStructureName"
          label="机构"
          width="120"
        ></el-table-column> -->
      <el-table-column
        prop="roleName"
        label="身份"
        width="100"
      ></el-table-column>
      <!-- <el-table-column
          prop="serviceCompanyName"
          label="服务公司"
          width="150"
        ></el-table-column> -->
      <!-- <el-table-column
          prop="showIconId"
          label="图标信息"
          width="150"
        ></el-table-column> -->
      <!-- <el-table-column
        prop="popularizePersonName"
        label="推广者"
        width="100"
      ></el-table-column> -->
      <!-- <el-table-column
        prop="remark"
        label="备注信息"
        width="100"
      ></el-table-column> -->
      <el-table-column
        prop="enableRegisterExamine"
        label="是否允许审核注册用户"
        width="120"
      >
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.enableRegisterExamine"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="handleUpdate(row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="enableAttendance" label="是否要求考勤" width="120">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.enableAttendance"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="handleUpdate(row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="useEnable" label="默认" width="100">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.isDefault"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="changeDefault(row)"
            :disabled="row.isDefault"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="{ row }">
          <el-link
            type="primary"
            :underline="false"
            @click="handleAllocMenu(row)"
            >自定义菜单</el-link
          >
          -
          <el-link type="warning" :underline="false" @click="handleEdit(row)"
            >编辑</el-link
          >
          -
          <el-link type="danger" :underline="false" @click="handleDelete(row)"
            >删除</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <personPopedom-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
      :personId="personId"
    ></personPopedom-detail>
    <personPopedom-icon
      v-if="showIconModal"
      :personPopedomId="businessKey"
      :title="iconModalTitle"
      @close="onIconModalClose"
    ></personPopedom-icon>
  </div>
</template>
<script>
import Constant from "@/constant";
import PersonPopedomDetail from "./personPopedom-detail";
import PersonPopedomIcon from "./personPopedom-icon";
import personPopedomApi from "@/api/base/personPopedom";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  props: ["personId"],
  data() {
    var self = this;

    return {
      queryModel: {
        personId: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      showDialog: true,
      showIconModal: false,
      iconModalTitle: "",
    };
  },
  methods: {
    loadThis() {
      this.changePage(1);
    },
    closeDialog() {
      this.$emit("close", false);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("personId", self.personId);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      personPopedomApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;
          console.log(jsonData.data);
          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
      this.personId = this.personId;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.operation = "edit";
      this.businessKey = record.id;
      this.personId = this.personId;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;
      this.$confirm("是否解除关联？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        distinguishCancelAndClose: true,
      })
        .then(() => {
          self.loading = true;

          personPopedomApi.remove(record.id).then(function (response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "解绑成功!",
              });
            } else {
              self.$message({
                type: "error",
                message: jsonData.message,
              });
            }
          });
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    onIconModalClose() {
      this.showIconModal = false;
    },
    handleReLoad() {
      var self = this;
      self.changePage(self.pageIndex);
    },
    handleUpdate(row) {
      personPopedomApi.update(row).then((response) => {
        var jsonData = response.data;
        this.loading = false;

        if (!jsonData.result) {
          self.$message({
            type: "error",
            message: jsonData.message + "",
          });
        }
      });
    },
    changeDefault(record) {
      var self = this;
      var formData = new FormData();
      self.loading = true;

      formData.append("id", record.id);
      formData.append("personId", this.personId);
      personPopedomApi.changeDefault(formData).then(function (response) {
        var jsonData = response.data;
        self.loading = false;
        if (jsonData.result) {
          self.changePage(self.pageIndex);
        }
      });
    },
    handleAllocMenu(record) {
      this.iconModalTitle = "自定义菜单";
      this.businessKey = record.id;
      this.showIconModal = true;
    },
  },
  mounted: function () {},
  components: {
    "personPopedom-detail": PersonPopedomDetail,
    "personPopedom-icon": PersonPopedomIcon,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>