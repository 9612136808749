<template>
  <div>
    <!--
      要resetFields起作用，必须配置:model和prop
      -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
      label-width="100px"
    >
      <el-form-item label="设备编号" prop="deviceNo">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.deviceNo"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备名称" prop="deviceName">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.deviceName"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchBound"
        >批量关联</el-button
      >
      <!-- <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-remove"
          @click="handleRemoveAll"
        >解绑所有设备</el-button>-->
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        :selectable="disableSelect"
        width="55"
      ></el-table-column>
      <el-table-column
        prop="deviceNo"
        label="设备编号"
        width="220"
      ></el-table-column>
      <el-table-column
        prop="aliasName"
        label="设备名称"
        width="180"
      ></el-table-column>
      <el-table-column prop="isOnline" label="运行状态" width="80">
        <template slot-scope="{ row }">
          <div
            v-if="row.isOnline"
            style="
              border-radius: 30px;
              background-color: #67c23a;
              width: 20px;
              height: 20px;
            "
          ></div>
          <div
            v-if="!row.isOnline"
            style="
              border-radius: 30px;
              background-color: #f56c6c;
              width: 20px;
              height: 20px;
            "
          ></div>
        </template>
      </el-table-column>
      <el-table-column prop="isBindPerson" label="关联状态" width="120">
        <template slot-scope="{ row }">{{
          row.isBind == 0 ? "未关联" : "已关联"
        }}</template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <span v-if="row.isBind == 0">
            <el-button size="mini" type="success" @click="handleBound(row)"
              >关联设备</el-button
            >
          </span>
          <!-- <span v-else>
              <el-button size="mini" type="danger" @click="handleDelete(row)">解除关联</el-button>
            </span>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <personDeviceRelation-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></personDeviceRelation-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import PersonDeviceRelationDetail from "./personDeviceRelation-detail";
import personDeviceRelationApi from "@/api/base/personDeviceRelation";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  props: ["personId"],
  data() {
    var self = this;

    return {
      formModel: {},
      queryModel: {
        deviceNo: "",
        deviceName: "",
        personId: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      showDialog: true,
    };
  },
  methods: {
    loadThis() {
      this.changePage(1);
    },
    closeDialog() {
      this.$emit("close", false);
    },
    disableSelect(row, rowIndex) {
      //多选按钮是否禁用
      if (row.isBindPerson) {
        return false;
      } else {
        return true;
      }
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("personId", self.personId);

      formData.append("deviceNo", self.queryModel.deviceNo);
      formData.append("deviceName", self.queryModel.deviceName);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      personDeviceRelationApi
        .isUnbindDeviceList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleBound(record) {
      var self = this;
      self
        .$confirm("是否立刻同步照片到设备？", "提示", {
          confirmButtonText: "同步到设备",
          cancelButtonText: "只绑定不同步",
          type: "warning",
          distinguishCancelAndClose: true,
        })
        .then(() => {
          self.formModel.personId = self.personId;
          self.formModel.deviceId = record.id;
          self.formModel.isWrite = true;

          self.loading = true;

          return personDeviceRelationApi
            .add(self.formModel)
            .then(function (response) {
              self.loading = false;

              var jsonData = response.data;
              self.changePage(self.pageIndex);
              if (jsonData.result) {
                self.$message({
                  message: "关联成功!",
                  type: "success",
                });
              } else {
                self.$message({
                  message: jsonData.message + "",
                  type: "warning",
                });
              }
            });
        })
        .catch((error) => {
          //self.loading = false;
          // self.$message.error(error + "");
          if (error === "cancel") {
            self.formModel.personId = self.personId;
            self.formModel.deviceId = record.id;
            self.formModel.isWrite = false;
            self.loading = true;
            return personDeviceRelationApi
              .add(self.formModel)
              .then(function (response) {
                self.loading = false;

                var jsonData = response.data;
                self.changePage(self.pageIndex);
                if (jsonData.result) {
                  self.$message({
                    message: "关联成功!",
                    type: "success",
                  });
                } else {
                  self.$message({
                    message: jsonData.message + "",
                    type: "warning",
                  });
                }
              });
          }
        });
    },
    handleBatchBound() {
      var self = this;
      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否立刻同步照片到设备？", "提示", {
        confirmButtonText: "同步到设备",
        cancelButtonText: "只绑定不同步",
        type: "warning",
        distinguishCancelAndClose: true,
      })
        .then(() => {
          self.loading = true;
          var formData = new FormData();

          formData.append("personId", self.personId);
          formData.append("devices", idList);
          formData.append("isWrite", true);

          personDeviceRelationApi
            .batchBindDevice(formData)
            .then(function (response) {
              var jsonData = response.data;
              self.changePage(self.pageIndex);
              if (jsonData.result) {
                self.$message({
                  type: "success",
                  message: "关联成功!",
                });
              } else {
                self.$message({
                  showClose: true,
                  dangerouslyUseHTMLString: true,
                  message: `有部分人员关联失败,<a href="${jsonData.message}" target="_blank">点击下载错误信息报表</a>&nbsp;`,
                  duration: 30000,
                });
              }
            });
        })
        .catch((error) => {
          //self.loading = false;
          if (error === "cancel") {
            self.loading = true;
            var formData = new FormData();
            formData.append("personId", self.personId);
            formData.append("devices", idList);
            formData.append("isWrite", false);
            personDeviceRelationApi
              .batchBindDevice(formData)
              .then(function (response) {
                var jsonData = response.data;
                self.changePage(self.pageIndex);
                if (jsonData.result) {
                  self.$message({
                    type: "success",
                    message: "关联成功!",
                  });
                } else {
                  self.$message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message: `有部分人员关联失败,<a href="${jsonData.message}" target="_blank">点击下载错误信息报表</a>&nbsp;`,
                    duration: 30000,
                  });
                }
              });
          }
        });
    },
    handleDelete(record) {
      var self = this;
      this.$confirm("是否解除关联？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        distinguishCancelAndClose: true,
      })
        .then(() => {
          self.loading = true;

          personDeviceRelationApi.remove(record.id).then(function (response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "解绑成功!",
              });
            }
          });
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    handleRemoveAll() {
      var self = this;

      this.$confirm("是否解除关联？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        distinguishCancelAndClose: true,
      })
        .then(() => {
          self.loading = true;
          var formData = new FormData();
          formData.append("personId", self.personId);

          personDeviceRelationApi
            .unbindDevice(formData)
            .then(function (response) {
              var jsonData = response.data;
              self.loading = false;
              if (jsonData.result) {
                self.changePage(self.pageIndex);

                self.$message({
                  type: "success",
                  message: "解绑成功!",
                });
              } else {
                self.$message({
                  type: "warning",
                  message: jsonData.message,
                });
              }
            });
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
  },
  mounted: function () {},
  components: {
    "personDeviceRelation-detail": PersonDeviceRelationDetail,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>