
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    title="临时工管理"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="80%"
  >
    <div>
      <!--
      要resetFields起作用，必须配置:model和prop
      -->
      <el-form
        ref="queryForm"
        :model="queryModel"
        inline
        class="demo-form-inline"
        label-width="100px"
      >
        <div>
          <el-form-item label="单位" prop="parentId">
            <el-select-tree
              size="mini"
              :props="props"
              :options="companyResult"
              v-model="queryModel.parentId"
              height="200"
            ></el-select-tree
            >&nbsp;
            <el-checkbox v-model="queryModel.subordinate"
              >是否包含下级单位</el-checkbox
            >
          </el-form-item>
          <el-form-item label="编号" prop="id">
            <el-input
              type="text"
              size="mini"
              style="width: 120px"
              v-model="queryModel.id"
            ></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="姓名" prop="name">
            <el-input
              type="text"
              size="mini"
              v-model="queryModel.name"
              style="width: 120px"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input
              type="text"
              size="mini"
              v-model="queryModel.phone"
              style="width: 120px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="mini"
              icon="ios-search"
              @click="changePage(1)"
              :loading="loading"
              >查询</el-button
            >&nbsp;
            <el-button
              type="primary"
              size="small"
              plain
              icon="el-icon-circle-plus"
              :disabled="multipleSelection.length == 0"
              @click="handleBatchChangeTime"
              >批量调整</el-button
            >
          </el-form-item>
        </div>
      </el-form>
      <el-divider></el-divider>
      <el-table
        :data="tableData"
        style="min-height: 400px"
        v-loading="loading"
        stripe
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
        :row-key="getRowKey"
      >
        <el-table-column
          type="selection"
          :selectable="disableSelect"
          :reserve-selection="true"
          width="55"
        ></el-table-column>
        <el-table-column
          prop="companyName"
          label="主要单位"
          width="250"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="id"
          label="人员编号"
          width="80"
        ></el-table-column>
        <el-table-column prop="name" label="姓名" width="80"></el-table-column>
        <el-table-column label="用工性质" width="120">
          <template slot-scope="{ row }">
            <span v-if="row.isTemporary">临时工</span>
            <span v-else>其他</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="temporaryEffectiveDate"
          label="到期时间"
          width="180"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button size="mini" type="success" @click="handleChangeTime(row)"
              >调整时间</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="pageIndex"
        :total="totalElements"
        :page-sizes="pageSizeList"
        @current-change="changePage"
        @size-change="pageSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
    <!-- 批量绑定设备 -->
    <el-dialog
      title="绑定设备"
      :visible.sync="batchChangeTime"
      style="text-align: left"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="150px"
        element-loading-text="正在操作,请稍等"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-form-item label="到期时间">
          <el-date-picker
            v-model="temporaryEffectiveDate"
            default-time="12:00:00"
            type="datetime"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeChangeTime">关 闭</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 批量绑定设备end -->
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import SelectTree from "@/components/SelectTree";

import companyInfoApi from "@/api/base/companyInfo";
import personInfoApi from "@/api/base/personInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  props: ["personId"],
  data() {
    var self = this;

    return {
      formModel: {},
      queryModel: {
        id: "",
        parentId: "",
        name: "",
        phone: "",
        idCard: "",
        subordinate: false,
        isTemporary: "1",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      showDialog: true,
      companyResult: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      batchChangeTime: false,
      temporaryEffectiveDate: "",
      ids: "",
    };
  },
  created() {
    var self = this;

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });
  },
  methods: {
    getRowKey(row) {
      return row.id;
    }, // val表示选中的表格行数据
    closeDialog() {
      this.$emit("close", false);
    },
    disableSelect(row, rowIndex) {
      //多选按钮是否禁用
      if (row.isBindPerson) {
        return false;
      } else {
        return true;
      }
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();
      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      if (self.queryModel.parentId == null) {
        self.queryModel.parentId = "";
      }
      formData.append("parentId", self.queryModel.parentId);

      formData.append("subordinate", self.queryModel.subordinate);

      formData.append("id", self.queryModel.id);
      formData.append("name", self.queryModel.name);
      formData.append("phone", self.queryModel.phone);
      formData.append("idCard", self.queryModel.idCard);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      formData.append("isTemporary", "1");
      personInfoApi
        .temporaryPageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    handleChangeTime(row) {
      this.batchChangeTime = true;
      this.temporaryEffectiveDate = row.temporaryEffectiveDate;
      this.ids = row.id;
    },
    handleBatchChangeTime() {
      this.batchChangeTime = true;
      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });
      this.ids = idList;
      this.temporaryEffectiveDate = this.multipleSelection[0].temporaryEffectiveDate;
    },
    closeChangeTime() {
      var self = this;
      self.batchChangeTime = false;
      self.ids = "";
    },
    handleSubmit() {
      var self = this;
      self.loading = true;

      var formData = new FormData();

      formData.append("persons", self.ids);
      formData.append("temporaryEffectiveDate", self.temporaryEffectiveDate);

      personInfoApi
        .changeEffectiveDate(formData)
        .then(function (response) {
          self.loading = false;
          self.ids = "";
          self.changePage(1);
          self.batchChangeTime = false;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
  },
  async mounted() {
    this.changePage(1);
  },
  components: {
    "el-select-tree": SelectTree,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>