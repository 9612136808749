<template>
  <div class="user-panel" v-loading="loading">
    <el-form
      ref="form"
      :model="formModel"
      :rules="ruleValidate"
      :label-width="'130px'"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item label="开放权限">
            <el-checkbox v-model="formModel.faceEnabled">人脸识别</el-checkbox>
            <el-checkbox v-model="formModel.cardEnabled">门禁卡</el-checkbox>
            <el-checkbox v-model="formModel.appEnabled">手机开锁</el-checkbox>
            <el-checkbox v-model="formModel.guestEnabled">访客权限</el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="同步人脸信息" prop="faceBound">
            <el-radio-group v-model="formModel.faceBound">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="微信通知" prop="wechatNoticeEnabled">
            <el-radio-group v-model="formModel.wechatNoticeEnabled">
              <el-radio :label="true">接受</el-radio>
              <el-radio :label="false">拒绝</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="是否允许查看本单位数据" prop="allowViewLocal">
            <el-radio-group v-model="formModel.allowViewLocal">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="拓展字段一" prop="position1">
            <el-input
              v-model="formModel.position1"
              placeholder="请输入拓展字段一1"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="拓展字段二" prop="position2">
            <el-input
              v-model="formModel.position2"
              placeholder="请输入拓展字段二"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row>
        <el-col :span="12">
          <el-form-item label="拓展字段三" prop="position3">
            <el-input
              v-model="formModel.position3"
              placeholder="请输入拓展字段三"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row> -->
      <!-- 
        <el-row>
          <el-col :span="12">
            <el-form-item :label="companyPosition4" prop="position4">
              <el-input
                v-model="formModel.position4"
                placeholder="请输入四级位置"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="companyPosition5" prop="position5">
              <el-input
                v-model="formModel.position5"
                placeholder="请输入五级位置"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
      <el-row>
        <el-col :span="24">
          <span style="text-align: right; display: block" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button
              type="primary"
              @click="handleSubmit"
              :loading="submitting"
              >确 定</el-button
            >
          </span>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import Constant from "@/constant";
import personInfoApi from "@/api/base/personInfo";

import { getToken } from "@/utils/auth"; // get token from cookie

export default {
  props: [
    "businessKey",
    "title",
    "companyResult",
    "companyPosition1",
    "companyPosition2",
    "companyPosition3",
    "companyPosition4",
    "companyPosition5",
  ],
  data() {
    return {
      formModel: {},
      ruleValidate: {},
      showDialog: true,
      loading: false,
      submitting: false,
      checkList: [],
      //上传地址
      uploadUrl: Constant.serverUrl + "/uploadPicture",
      uploadData: {
        subFolder: "personInfo",
      },
      fileUrl: "",
      headers: {
        Authorization: getToken(),
      },
      personRoleResult: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      parkList: [],
      workStatusResult: [],
      healthyCodeList: [
        { name: "绿码", value: "00" },
        { name: "黄码", value: "01" },
        { name: "红码", value: "02" },
        { name: "灰码", value: "11" },
      ],
    };
  },
  methods: {
    loadThis() {
      var self = this;
      (function () {
        if (self.businessKey.length == 0) {
          return personInfoApi.create();
        } else {
          return personInfoApi.edit(self.businessKey);
        }
      })()
        .then((response) => {
          var jsonData = response.data;
          self.loading = false;

          if (jsonData.result) {
            self.formModel = jsonData.data;
            let faceImageUrl = self.formModel.faceImageUrl;
            if (faceImageUrl != null) {
              self.fileUrl =
                faceImageUrl + "?x-oss-process=image/resize,m_lfit,h_400,w_400";
            }

            if (self.businessKey.length == 0) {
              //self.formModel.popedom = ["1"];
            } else {
              // var popedom = self.formModel.popedom;
              // if (popedom != "") {
              //   self.formModel.popedom = popedom.split(",");
              // }

              var personCompany = self.formModel.personCompany;
              if (personCompany != "") {
                self.formModel.personCompany = personCompany.split(",");
              }

              var wechatNoticeEnabled = self.formModel.wechatNoticeEnabled;
              if (wechatNoticeEnabled != "") {
                self.formModel.wechatNoticeEnabled = true;
              }
            }
          } else {
            self.$message.error(jsonData.message + "");
          }
        })
        .catch((error) => {
          self.$message.error(error + "");
        });
    },
    getSelectedValue(value) {
      this.formModel.companyId = value;
    },
    getSelectedParkValue(value) {
      this.formModel.parkId = value;
    },
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;
            //数组转换为字符串
            //用户身份
            // if (
            //   self.formModel.popedom != null &&
            //   self.formModel.popedom != ""
            // ) {
            //   var popedom = self.formModel.popedom.join(",");
            //   self.formModel.popedom = popedom;
            // } else {
            //   self.formModel.popedom = "";
            // }

            //子单位
            if (
              self.formModel.personCompany != null &&
              self.formModel.personCompany != ""
            ) {
              var personCompany = self.formModel.personCompany.join(",");
              self.formModel.personCompany = personCompany;
            } else {
              self.formModel.personCompany = "";
            }

            if (self.formModel.companyId == null) {
              self.formModel.companyId = "";
            }

            if (id == null || id.length == 0) {
              return personInfoApi.add(self.formModel);
            } else {
              return personInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;
            self.loadThis();
            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              //self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {},
};
</script>