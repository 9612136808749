<template>
  <div style="position: relative">
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">基础信息管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/personInfo">人员管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
        要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <div>
        <el-form-item label="编号" prop="id">
          <el-input
            type="text"
            size="mini"
            style="width: 120px"
            v-model="queryModel.id"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.name"
            style="width: 120px"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.phone"
            style="width: 120px"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证" prop="idCard">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.idCard"
            style="width: 190px"
          ></el-input>
        </el-form-item>
        <el-form-item label="绑定设备名称" prop="derviceName">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.derviceName"
            style="width: 120px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="changePage(1)"
            :loading="loading"
            >查询</el-button
          >&nbsp;
          <el-button
            type="info"
            size="mini"
            style="margin-left: 8px"
            @click="handleReset('queryForm')"
            >重置</el-button
          >&nbsp;
        </el-form-item>
      </div>
      <div>
        <el-form-item label="单位" prop="parentId">
          <el-select-tree
            size="mini"
            :props="props"
            :options="companyResult"
            v-model="queryModel.parentId"
            height="200"
          ></el-select-tree
          >&nbsp;
          <el-checkbox v-model="queryModel.subordinate"
            >是否包含下级单位</el-checkbox
          >
        </el-form-item>
        <el-form-item label="身份" prop="roleId">
          <el-select
            v-model="queryModel.roleId"
            filterable
            clearable
            size="mini"
            placeholder="请选择"
            style="width: 120px"
          >
            <el-option
              v-for="role in roleResult"
              :key="role.id"
              :label="role.personRoleName"
              :value="role.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否上传照片" prop="isUploadPhoto">
          <el-select
            v-model="queryModel.isUploadPhoto"
            size="mini"
            clearable
            placeholder="请选择"
            style="width: 90px"
          >
            <el-option value="1" label="已上传"></el-option>
            <el-option value="0" label="未上传"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="是否同步" prop="faceBound">
          <el-select
            v-model="queryModel.faceBound"
            size="mini"
            clearable
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option value label="全部"></el-option>
            <el-option value="1" label="已同步"></el-option>
            <el-option value="0" label="未同步"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="是否需要考勤" prop="enableAttendance">
          <el-select
            v-model="queryModel.enableAttendance"
            size="mini"
            clearable
            placeholder="请选择"
            style="width: 90px"
          >
            <el-option :value="true" label="是"></el-option>
            <el-option :value="false" label="否"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="允许审核注册用户" prop="enableRegisterExamine">
          <el-select
            v-model="queryModel.enableRegisterExamine"
            size="mini"
            clearable
            placeholder="请选择"
            style="width: 90px"
          >
            <el-option :value="true" label="是"></el-option>
            <el-option :value="false" label="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="健康码" prop="healthyCode">
          <el-select
            v-model="queryModel.healthyCode"
            size="mini"
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option
              v-for="item in healthyCodeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <!-- <div>
        <el-form-item v-if="position1Show" :label="position1" prop="position1">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.position1"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="position2Show" :label="position2" prop="position2">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.position2"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="position3Show" :label="position3" prop="position3">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.position3"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="position4Show" :label="position4" prop="position4">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.position4"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="position5Show" :label="position5" prop="position5">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.position5"
          ></el-input>
        </el-form-item>
      </div> -->
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchDelete"
        >删除选中项</el-button
      >
      <!-- <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchClearFaceImg"
        >清除设备照片</el-button
      > -->
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-upload2"
        @click="batchImportVisible = true"
        >导入</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        :loading="xlsLoading"
        @click="downloadAll"
        >导出数据</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-refresh"
        :disabled="multipleSelection.length == 0"
        @click="dataSync"
        >数据同步</el-button
      >
      <!-- <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-refresh"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchEnabledFace"
        >开启自动同步</el-button
      > -->
      <!-- <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-refresh"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchEnabledFace"
        >人脸授权</el-button> -->
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-refresh"
        :disabled="multipleSelection.length == 0"
        @click="batchBoundDevice = true"
        >关联设备</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-refresh"
        :disabled="multipleSelection.length == 0"
        @click="batchUnBoundDevice = true"
        >解绑设备</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-refresh"
        @click="handleTemporary(row)"
        >临时工管理</el-button
      >
      <el-dropdown style="margin: 0px 10px">
        <el-button type="primary" size="small" plain>
          更多菜单<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            :disabled="multipleSelection.length == 0"
            @click.native="handleBatchClearFaceImg"
            >清除设备照片</el-dropdown-item
          >
          <el-dropdown-item
            :disabled="multipleSelection.length == 0"
            @click.native="handleBatchEnabledFace"
            >人脸授权</el-dropdown-item
          >
          <el-dropdown-item @click.native="downloadZip" v-if="batchDownloadImgVisible"
            >打包下载照片</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      :height="tableHeight"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="id"
        label="人员编号"
        width="80"
        fixed="left"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        width="80"
        fixed="left"
      ></el-table-column>
      <el-table-column
        prop="faceImageUrl"
        label="人员照片"
        width="80"
        fixed="left"
      >
        <template slot-scope="{ row }">
          <a :href="row.faceImageUrl" target="_blank">
            <el-avatar
              :size="48"
              shape="circle"
              :src="
                row.faceImageUrl +
                '?x-oss-process=image/resize,m_fill,w_64,h_64'
              "
              :key="row.id"
            ></el-avatar>
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="companyName"
        label="主要单位"
        width="250"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="popedomName"
        label="主要身份"
        width="120"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="mechanism"
        label="所有单位身份"
        width="250"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="手机号"
        width="180"
      ></el-table-column>
      <!-- <el-table-column
        prop="idCard"
        label="身份证"
        width="100"
        show-overflow-tooltip
      ></el-table-column> -->
      <el-table-column prop="healthyCodeN" label="健康码" width="80">
        <template slot-scope="{ row }">
          <span v-if="row.healthyCode">
            <span v-if="row.healthyCode == '00'" style="color: green"
              >绿码</span
            >
            <span v-else-if="row.healthyCode == '01'" style="color: yellow"
              >黄码</span
            >
            <span v-else-if="row.healthyCode == '02'" style="color: red"
              >红码</span
            >
            <span v-else style="color: gray">灰码</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="工作状态" width="120">
        <template slot-scope="{ row }">
          <span v-if="row.workStatus == 1">工作</span>
          <span v-else-if="row.workStatus == 2">外出</span>
          <span v-else-if="row.workStatus == 3">出差</span>
          <span v-else-if="row.workStatus == 4">休假</span>
          <span v-else>未设置</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="deviceName"
        label="绑定设备"
        width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="openId" label="是否绑定公众号" width="180">
        <template slot-scope="{ row }">{{
          row.openId == null ? "否" : "是"
        }}</template>
      </el-table-column>
      <el-table-column prop="faceBound" label="是否同步人脸信息" width="180">
        <template slot-scope="{ row }">{{
          row.faceBound ? "是" : "否"
        }}</template>
      </el-table-column>
      <el-table-column label="是否自动同步" width="120">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.isSync"
            @change="enabledTo(row, 'sync', row.isSync)"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :disabled="disabled"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="人脸授权">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.faceEnabled"
            @change="enabledTo(row, 'face', row.faceEnabled)"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :disabled="disabled"
          ></el-switch>
        </template>
      </el-table-column>
      <!-- <el-table-column label="刷卡授权">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.cardEnabled"
            @change="enabledTo(row, 'card', '')"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :disabled="disabled"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="手机授权">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.appEnabled"
            @change="enabledTo(row, 'app', '')"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :disabled="disabled"
          ></el-switch>
        </template>
      </el-table-column> -->
      <!-- <el-table-column label="密码">
        <template slot-scope="{row}">
          <el-switch v-model="row.passwordEnabled" @change="enabledTo(row.id,'face')" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </template>
      </el-table-column>-->
      <!-- <el-table-column label="访客授权">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.guestEnabled"
            @change="enabledTo(row, 'guest', '')"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :disabled="disabled"
          ></el-switch>
        </template>
      </el-table-column> -->
      <el-table-column label="接收微信通知">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.wechatNoticeEnabled"
            @change="enabledTo(row, 'wechatNotice', '')"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :disabled="disabled"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="openId"
        label="微信openid"
        width="180"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="hkPersonId"
        label="海康云眸社区人员编号"
        width="300"
      >
        <template slot-scope="{ row }">
          <span v-if="row.hkPersonId">
            <el-row>
              <el-col>
                <el-link
                  type="danger"
                  :underline="false"
                  @click="handleDelHkPerson(row)"
                  >删除社区用户</el-link
                >
              </el-col>
              <el-col>
                {{ row.hkPersonId }}
              </el-col>
            </el-row>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180" fixed="right">
        <template slot-scope="{ row }">
          <el-row>
            <el-col>
              <span v-if="row.delFlag">
                <el-link
                  type="danger"
                  :underline="false"
                  @click="handleClearFaceImg(row)"
                  >清除图片</el-link
                >-
              </span>
              <span v-else>
                <el-link
                  type="primary"
                  :underline="false"
                  @click="handleEdit(row)"
                  >编辑</el-link
                >-<el-link
                  type="primary"
                  :underline="false"
                  @click="unbindWechat(row)"
                  >微信解绑</el-link
                >-
                <el-link
                  type="danger"
                  :underline="false"
                  @click="handleDelete(row)"
                  >删除</el-link
                >
                <!-- -
                <el-link
                  type="primary"
                  :underline="false"
                  @click="bindRole(row)"
                  >机构身份管理</el-link
                >-
                <span v-if="row.faceBound">
                  <el-link
                    type="primary"
                    :underline="false"
                    :disabled="true"
                    @click="uploadData(row)"
                    >数据同步</el-link
                  >-
                </span>
                <span v-if="!row.faceBound">
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="uploadData(row)"
                    >数据同步</el-link
                  >-
                </span> -->
              </span>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col>
              <el-link
                type="primary"
                :underline="false"
                @click="bindDevice(row)"
                >关联设备</el-link
              >-
              <el-link type="primary" :underline="false" @click="showBound(row)"
                >已关联设备</el-link
              >-
              <el-link
                type="primary"
                :underline="false"
                @click="showLifeRecord(row)"
                >生活记录</el-link
              >-
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-link
                type="primary"
                :underline="false"
                @click="bindWechat(row)"
                >微信绑定</el-link
              >-
              
              <el-link
                type="primary"
                :underline="false"
                @click="updateHealthyCode(row)"
                >更新健康码</el-link
              >
            </el-col>
          </el-row> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <!-- <personInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      :companyResult="companyResult"
      :companyPosition1="position1"
      :companyPosition2="position2"
      :companyPosition3="position3"
      :companyPosition4="position4"
      :companyPosition5="position5"
      @close="onDetailModalClose"
    ></personInfo-detail> -->
    <personInfo-detailTabs
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      :companyResult="companyResult"
      :companyPosition1="position1"
      :companyPosition2="position2"
      :companyPosition3="position3"
      :companyPosition4="position4"
      :companyPosition5="position5"
      @close="onDetailModalClose"
    ></personInfo-detailTabs>
    <personDeviceRelation-list
      v-if="showDeviceModal"
      :personId="personId"
      @close="onDetailModalClose"
    ></personDeviceRelation-list>
    <personDeviceRelation-BoundList
      v-if="showBoundModal"
      :personId="personId"
      @close="onDetailModalClose"
    ></personDeviceRelation-BoundList>
    <!--批量导入S-->
    <!--生活记录 start-->
    <lifeRecord-list
      v-if="showRecordModal"
      :personId="businessKey"
      @close="recordModalClose"
    ></lifeRecord-list>
    <!--生活记录 end-->
    <el-dialog
      title="批量导入人员"
      :visible.sync="batchImportVisible"
      :modal-append-to-body="false"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <el-form label-width="150px">
        <el-form-item label="模板下载">
          <el-link
            href="http://rccs.oss-cn-hangzhou.aliyuncs.com/smart/personInfo/2020/10/小鹏管家人员导入模板.xls"
            type="primary"
            target="_blank"
            >点击下载模板</el-link
          >
        </el-form-item>
        <el-form-item label="单位">
          <el-select-tree
            size="large"
            :props="props"
            :options="companyResult"
            v-model="uploadXlsData.companyId"
            height="200"
            width="300px"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="用户身份" prop="personRoleId">
          <el-select
            filterable
            placeholder="请选择"
            style="width: 300px"
            v-model="uploadXlsData.personRoleId"
          >
            <el-option
              v-for="role in personRoleResult"
              :key="role.personRoleName"
              :label="role.personRoleName"
              :value="role.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传文件">
          <el-upload
            class="upload-demo"
            accept=".xls"
            :action="uploadUrlXls"
            :data="uploadXlsData"
            :headers="headers"
            :on-preview="handleBatchImportPreview"
            name="uploadFile"
            :multiple="true"
            :limit="1"
            :on-remove="handleBatchImportRemove"
            :before-remove="beforeBatchImportRemove"
            :before-upload="beforeUpload"
            :on-exceed="handleBatchImportExceed"
            :on-success="handleBatchImportSuccess"
            :file-list="batchImportFileList"
          >
            <el-button size="small" type="primary" :loading="xlsLoading"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">
              只能上传xls文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="batchImportVisible = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
    <!--批量导入E-->
    <!-- 批量绑定设备 -->
    <el-dialog
      title="绑定设备"
      :visible.sync="batchBoundDevice"
      :modal-append-to-body="false"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="150px"
        v-loading="boundDeviceDisabled"
        element-loading-text="正在操作,请稍等"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-form-item label="设备">
          <el-select
            v-model="boundDeviceIds"
            filterable
            placeholder="请选择"
            multiple
            style="width: 300px"
          >
            <el-option
              v-for="devices in deviceResult"
              :key="devices.id"
              :label="devices.aliasName"
              :value="devices.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeBatchBoundDevice">关 闭</el-button>
        <el-button type="primary" @click="handleBatchBoundDevice"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 批量绑定设备end -->
    <!-- 批量解绑设备 -->
    <el-dialog
      title="解绑设备"
      :visible.sync="batchUnBoundDevice"
      :modal-append-to-body="false"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <el-form label-width="150px" v-loading="boundDeviceDisabled">
        <el-form-item label="设备">
          <el-select v-model="boundUnDeviceId" filterable placeholder="请选择">
            <el-option
              v-for="devices in deviceResult"
              :key="devices.id"
              :label="devices.aliasName"
              :value="devices.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeBatchUnBoundDevice">关 闭</el-button>
        <el-button type="primary" @click="handleBatchUnBoundDevice"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 批量解绑设备end -->
    <el-dialog
      title="扫码绑定微信"
      :visible.sync="bindWechatVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      width="340px"
    >
      <div v-loading="bindWechatLoading">
        <img :src="bindWechatUrl" style="width: 300px; fit-object: cover" />
      </div>
    </el-dialog>
    <personPopedom-list
      v-if="showRoleModal"
      :personId="personId"
      @close="onDetailModalClose"
    ></personPopedom-list>
    <personInfo-temporary
      v-if="showTemporaryModal"
      :personId="personId"
      @close="onTemporaryModalClose"
    ></personInfo-temporary>
  </div>
</template>
<script>
import Constant from "@/constant";
import PersonInfoDetail from "./personInfo-detail";
import PersonInfoDetailTabs from "./personInfo-detailTabs";
import PersonDeviceRelationList from "./personDeviceRelation-list";
import PersonDeviceRelationBoundList from "./personDeviceRelation-BoundList";
import PersonPopedomList from "./personPopedom-list";

import PersonInfoTemporary from "./personInfo-temporary";

import personInfoApi from "@/api/base/personInfo";
import companyInfoApi from "@/api/base/companyInfo";
import personRoleInfoApi from "@/api/base/personRoleInfo";
import companyPositionApi from "@/api/base/companyPosition";
import deviceInfoApi from "@/api/base/deviceInfo";
import personDeviceRelationApi from "@/api/base/personDeviceRelation";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import SelectTree from "@/components/SelectTree";
import LifeRecordList from "./lifeRecord-list";
import permissionApi from "@/api/sys/permission";

import { getToken } from "@/utils/auth"; // get token from cookie
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "BasePersonInfoList",
  data() {
    var self = this;

    return {
      queryModel: {
        id: "",
        companyCode: "",
        parentId: "",
        name: "",
        phone: "",
        idCard: "",
        faceBound: "",
        isUploadPhoto: "",
        subordinate: false,
        position1: "",
        position2: "",
        position3: "",
        position4: "",
        position5: "",
        derviceName: "",
        parkId: "",
        roleId: "",
        enableAttendance: null,
        enableRegisterExamine: null,
        healthyCode: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 30,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [30, 100, 500],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      companyResult: [],
      batchImportVisible: false,
      batchImportFileList: [],
      uploadUrlXls: Constant.serverUrl + "/base/personInfo/importXls",
      uploadXlsData: {
        subFolder: "company",
        companyId: "",
        personRoleId: "",
      },
      personRoleResult: [],
      headers: {
        Authorization: getToken(),
      },
      disabled: false,
      position1: "",
      position2: "",
      position3: "",
      position4: "",
      position5: "",
      position1Show: false,
      position2Show: false,
      position3Show: false,
      position4Show: false,
      position5Show: false,
      showDeviceModal: false,
      showBoundModal: false,
      personId: "",
      batchBoundDevice: false,
      batchUnBoundDevice: false,
      deviceResult: "",
      boundDeviceIds: [],
      boundUnDeviceId: "",
      xlsLoading: false,
      tableHeight: 400,
      boundDeviceDisabled: false,
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      showRecordModal: false,
      bindWechatVisible: false,
      bindWechatUrl: "",
      bindWechatLoading: false,
      parkList: [],
      showRoleModal: false,
      roleResult: [],
      healthyCodeList: [],
      showTemporaryModal: false,
      batchDownloadImgVisible:false,
    };
  },
  watch: {
    "uploadXlsData.companyId": function (newVal, oldVal) {
      var self = this;

      if (newVal != null) {
        var formData = new FormData();
        formData.append("companyId", newVal);

        //查询单位下角色
        personRoleInfoApi.listByCompanyId(formData).then((response) => {
          var jsonData = response.data;

          if (jsonData.result) {
            self.personRoleResult = jsonData.data;
          }
        });
      }
    },
    "queryModel.parentId": function (val, oldval) {
      if (val != null && val != "") {
        var self = this;
        var formData = new FormData();
        formData.append("companyId", val);
        personRoleInfoApi.listByCompanyId(formData).then(function (response) {
          var jsonData = response.data;
          if (jsonData.result) {
            if (jsonData.data != null && jsonData.data != "") {
              self.roleResult = jsonData.data;
            }
          }
        });
      }
    },
  },
  created() {
    var self = this;

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });

    var formData = new FormData();
    formData.append("catalogName", "健康码");
    dataDictionaryApi.findByCatalogName(formData).then((response) => {
      var jsonData = response.data;
      this.healthyCodeList = jsonData.data;
    });

    // companyInfoApi.list({ type: "3" }).then((response) => {
    //   var jsonData = response.data;
    //   this.parkList = jsonData.data;
    // });

    deviceInfoApi.list().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.deviceResult = jsonData.data;
        }
      }
    });

    companyPositionApi.detailForCompany().then(function (response) {
      var jsonData = response.data.data;
      if (jsonData.position1Name != null && jsonData.position1Name != "") {
        self.position1 = jsonData.position1Name;
        self.position1Show = true;
      }
      if (jsonData.position2Name != null && jsonData.position2Name != "") {
        self.position2 = jsonData.position2Name;
        self.position2Show = true;
      }
      if (jsonData.position3Name != null && jsonData.position3Name != "") {
        self.position3 = jsonData.position3Name;
        self.position3Show = true;
      }
      if (jsonData.position4Name != null && jsonData.position4Name != "") {
        self.position4 = jsonData.position4Name;
        self.position4Show = true;
      }
      if (jsonData.position5Name != null && jsonData.position5Name != "") {
        self.position5 = jsonData.position5Name;
        self.position5Show = true;
      }
    });

    permissionApi
      .hasPermission("post", "/base/personInfo/downloadImg")
      .then((response) => {
        this.batchDownloadImgVisible = response.data.result;
      });

    this.loadTree();
  },
  methods: {
    getSelectedValue(value) {
      this.queryModel.parentId = value;
    },
    loadTree() {
      companyInfoApi.treeList().then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      console.log(tree);

      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    adjustTableHeight() {
      var self = this;
      self.tableHeight =
        window.innerHeight - self.$refs.formTable.$el.offsetTop - 110;

      if (self.tableHeight < 400) {
        self.tableHeight = 400;
      }
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      if (self.queryModel.parentId == null) {
        self.queryModel.parentId = "";
      }
      formData.append("companyCode", self.queryModel.companyCode);
      formData.append("parentId", self.queryModel.parentId);

      formData.append("subordinate", self.queryModel.subordinate);

      formData.append("id", self.queryModel.id);
      formData.append("name", self.queryModel.name);
      formData.append("phone", self.queryModel.phone);
      formData.append("idCard", self.queryModel.idCard);
      formData.append("faceBound", self.queryModel.faceBound);
      formData.append("isUploadPhoto", self.queryModel.isUploadPhoto);

      if (self.queryModel.enableRegisterExamine != null) {
        formData.append(
          "enableRegisterExamine",
          self.queryModel.enableRegisterExamine
        );
      }

      if (self.queryModel.enableAttendance != null) {
        formData.append("enableAttendance", self.queryModel.enableAttendance);
      }

      formData.append("position1", self.queryModel.position1);
      formData.append("position2", self.queryModel.position2);
      formData.append("position3", self.queryModel.position3);
      formData.append("position4", self.queryModel.position4);
      formData.append("position5", self.queryModel.position5);

      formData.append("derviceName", self.queryModel.derviceName);

      formData.append("roleId", self.queryModel.roleId);

      if (self.queryModel.parkId == null) {
        self.queryModel.parkId = "";
      }
      formData.append("parkId", self.queryModel.parkId);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      formData.append("healthyCode", self.queryModel.healthyCode);

      personInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;

          //页面高度-列表上面的高度-分页栏高度
          //45为分页栏的高度
          self.adjustTableHeight();
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.changePage(this.pageIndex);
    },
    sortChange(data) {
      this.field = data.column.field;
      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;
      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          self.loading = true;
          personInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          self.loading = false;
        });
    },
    handleBatchDelete() {
      var self = this;
      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        self.loading = true;
        personInfoApi
          .batchRemove(idList)
          .then(function (response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          })
          .catch(() => {
            self.loading = false;
          });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;
      this.showDeviceModal = false;
      this.showBoundModal = false;
      this.showRoleModal = false;
      this.showTemporaryModal = false;
      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    bindDevice(record) {
      //绑定设备
      this.personId = record.id;
      this.showDeviceModal = true;
    },
    showBound(record) {
      //展示绑定设备
      //人员信息ID
      this.personId = record.id;
      this.showBoundModal = true;
    },
    beforeUpload(file, fileList) {
      //导入前判断
      var self = this;
      var companyId = self.uploadXlsData.companyId;

      if (companyId == null || companyId.length == 0) {
        self.$message({
          type: "warning",
          message: "请选择单位!",
        });
        self.xlsLoading = false;
        return false;
      } else {
        self.xlsLoading = true;
        return true;
      }
    },
    //批量导入-上传成功
    handleBatchImportSuccess(response, file, fileList) {
      var self = this;
      self.xlsLoading = false;
      self.uploadXlsData.companyId = null;

      if (response.result) {
        self.$message.success(response.message);
        this.batchImportFileList = [];
        this.changePage(1);

        this.batchImportVisible = false;
      } else {
        //this.$message.error(response.message);
        this.batchImportFileList = [];
        this.changePage(1);

        if (response.data != null) {
          //下载有错误信息提示的报表
          //window.open(response.data);
          self.$message({
            showClose: true,
            dangerouslyUseHTMLString: true,
            message:
              response.message +
              `,<a href="${response.data}" target="_blank">点击下载未导入的数据报表</a>&nbsp;`,
            duration: 30000,
          });
        }
      }
    },
    //批量导入-预览
    handleBatchImportPreview(file) {
      console.log(file.url);
    },
    //批量导入-移除
    handleBatchImportRemove(file, fileList) {
      console.log(file, fileList);
    },
    //批量导入-移除前操作
    beforeBatchImportRemove(file, fileList) {
      console.log(file, fileList);
    },
    //批量导入-文件超出个数限制时的钩子
    handleBatchImportExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    downloadAll() {
      //导出
      var self = this;
      var formData = new FormData();

      if (self.queryModel.parentId == null) {
        self.queryModel.parentId = "";
      }
      formData.append("companyCode", self.queryModel.companyCode);
      formData.append("parentId", self.queryModel.parentId);
      formData.append("subordinate", self.queryModel.subordinate);

      formData.append("id", self.queryModel.id);
      formData.append("name", self.queryModel.name);
      formData.append("phone", self.queryModel.phone);
      formData.append("idCard", self.queryModel.idCard);
      formData.append("faceBound", self.queryModel.faceBound);
      formData.append("isUploadPhoto", self.queryModel.isUploadPhoto);

      if (self.queryModel.enableRegisterExamine != null) {
        formData.append(
          "enableRegisterExamine",
          self.queryModel.enableRegisterExamine
        );
      }

      if (self.queryModel.enableAttendance != null) {
        formData.append("enableAttendance", self.queryModel.enableAttendance);
      }

      formData.append("position1", self.queryModel.position1);
      formData.append("position2", self.queryModel.position2);
      formData.append("position3", self.queryModel.position3);
      formData.append("position4", self.queryModel.position4);
      formData.append("position5", self.queryModel.position5);

      self.xlsLoading = true;

      personInfoApi.exportXls(formData).then(function (response) {
        self.xlsLoading = false;
        var jsonData = response.data;
        if (jsonData.result) {
          if (jsonData.data != null) {
            if (document.location.href.startsWith("https://")) {
              jsonData.data = jsonData.data.replace("http://", "https://");
            }

            self.$message({
              showClose: true,
              type: "success",
              dangerouslyUseHTMLString: true,
              message: `报表已生成，<a href="${jsonData.data}" target="_blank">点击下载报表</a>&nbsp;`,
              duration: 30000,
            });
          }
        }
      });
    },
    enabledTo(row, type, value) {
      var self = this;

      if (type == "wechatNotice") {
        var formData = new FormData();
        formData.append("id", row.id);

        personInfoApi.enabledWechatNotice(formData);
      } else {
        this.$confirm("该操作会对设备上的信息进行操作,请确认？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            //self.loading = true;
            if (!self.disabled) {
              var formData = new FormData();
              formData.append("id", row.id);
              if ("face" == type) {
                personInfoApi.enabledFace(formData);
              } else if ("card" == type) {
                personInfoApi.enabledCard(formData);
              } else if ("app" == type) {
                personInfoApi.enabledApp(formData);
              } else if ("guest" == type) {
                personInfoApi.enabledGuest(formData);
              } else if ("wechatNotice" == type) {
                personInfoApi.enabledWechatNotice(formData);
              } else if ("sync" == type) {
                personInfoApi.enabledSync(formData);
              }

              //关闭开关,是自动同步到服务器
              //打开开关需要手动同步服务器
              // if (!value) {
              //   //如果是打开开关,则修改状态
              //   row.faceBound = false;
              // }

              self.disabled = true;

              setTimeout(function () {
                self.disabled = false;
                //self.loading = false;
              }, 1000); //一秒内不能重复点击
            } else {
              self.$message({
                type: "success",
                message: "操作过快!",
              });
            }
          })
          .catch(() => {
            // self.loading = false;
            if (row.faceEnabled) {
              row.faceEnabled = false;
            } else {
              row.faceEnabled = true;
            }
          });
      }
    },
    uploadData(record) {
      //同步人脸数据
      var self = this;
      self.loading = true;
      var uploadSync = new Array(0);
      uploadSync.push(record.id);

      personInfoApi.dataSync(uploadSync).then(function (response) {
        var jsonData = response.data;
        self.loading = false;
        if (jsonData.result) {
          if (jsonData.data) {
            self.changePage(self.pageIndex);
            self.$message({
              type: "success",
              message: "同步成功!",
            });
          } else {
            if (jsonData.message != null) {
              //下载有错误信息提示的报表
              //window.open(response.data);
              self.$message({
                showClose: true,
                dangerouslyUseHTMLString: true,
                message:
                  "错误" +
                  `,<a href="${jsonData.message}" target="_blank">点击下载错误报表</a>&nbsp;`,
                duration: 30000,
              });
            }
          }
        } else {
          self.$message({
            type: "warning",
            message: jsonData.message,
          });
        }
      });
    },
    dataSync() {
      //批量同步人脸
      var self = this;
      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认同步选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          self.loading = true;
          personInfoApi.dataSync(idList).then(function (response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              if (jsonData.data) {
                self.changePage(self.pageIndex);
                self.$message({
                  type: "success",
                  message: "同步成功!",
                });
              } else {
                if (jsonData.message != null) {
                  //下载有错误信息提示的报表
                  //window.open(response.data);
                  self.$message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message:
                      "错误" +
                      `,<a href="${jsonData.message}" target="_blank">点击下载错误报表</a>&nbsp;`,
                    duration: 30000,
                  });
                }
              }
            } else {
              self.$message({
                type: "warning",
                message: jsonData.message,
              });
            }
          });
        })
        .catch(() => {
          self.loading = false;
        });
    },
    handleBatchBoundDevice() {
      //批量人脸
      var self = this;
      var formData = new FormData();

      //选择设备
      let boundDeviceIds = self.boundDeviceIds;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });
      self
        .$confirm("是否立刻同步照片到设备？", "提示", {
          confirmButtonText: "同步到设备",
          cancelButtonText: "只绑定不同步",
          type: "warning",
          distinguishCancelAndClose: true,
        })
        .then(() => {
          boundDeviceIds.forEach((boundDeviceId) => {
            var formData = new FormData();
            self.boundDeviceDisabled = true;

            formData.append("persons", idList);
            formData.append("deviceId", boundDeviceId);
            formData.append("isWrite", true);

            personDeviceRelationApi
              .batchBindPerson(formData)
              .then(function (response) {
                var jsonData = response.data;
                //self.loading = false;
                self.boundDeviceDisabled = false;
                if (jsonData.result) {
                  self.changePage(self.pageIndex);
                  self.batchBoundDevice = false;
                  self.boundDeviceId = "";
                  self.$message({
                    type: "success",
                    message: "绑定成功!",
                  });
                } else {
                  self.$message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message: `有部分人员绑定失败,<a href="${jsonData.message}" target="_blank">点击下载错误信息报表</a>&nbsp;`,
                    duration: 30000,
                  });
                }
              });
          });
        })
        .catch((error) => {
          if (error === "cancel") {
            boundDeviceIds.forEach((boundDeviceId) => {
              var formData = new FormData();
              self.boundDeviceDisabled = true;

              formData.append("persons", idList);
              formData.append("deviceId", boundDeviceId);
              formData.append("isWrite", false);

              personDeviceRelationApi
                .batchBindPerson(formData)
                .then(function (response) {
                  var jsonData = response.data;
                  //self.loading = false;
                  self.boundDeviceDisabled = false;
                  if (jsonData.result) {
                    self.changePage(self.pageIndex);
                    self.batchBoundDevice = false;
                    self.boundDeviceId = "";
                    self.$message({
                      type: "success",
                      message: "绑定成功!",
                    });
                  } else {
                    self.$message({
                      showClose: true,
                      dangerouslyUseHTMLString: true,
                      message: `有部分人员绑定失败,<a href="${jsonData.message}" target="_blank">点击下载错误信息报表</a>&nbsp;`,
                      duration: 30000,
                    });
                  }
                });
            });
          } else {
            self.boundDeviceDisabled = false;
          }
        });
    },
    handleBatchUnBoundDevice() {
      //批量人脸
      var self = this;
      //self.loading = true;
      self.boundDeviceDisabled = true;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });
      var formData = new FormData();

      formData.append("persons", idList);
      formData.append("deviceId", self.boundUnDeviceId);
      personDeviceRelationApi
        .batchUnBindPerson(formData)
        .then(function (response) {
          var jsonData = response.data;
          //self.loading = false;
          self.boundDeviceDisabled = false;
          if (jsonData.result) {
            self.changePage(self.pageIndex);
            self.batchBoundDevice = false;
            self.boundDeviceId = "";
            self.$message({
              type: "success",
              message: "解除绑定成功!",
            });
          }
        });
    },
    closeBatchBoundDevice() {
      var self = this;
      self.batchBoundDevice = false;
      self.boundDeviceId = "";
    },
    closeBatchUnBoundDevice() {
      var self = this;
      self.batchUnBoundDevice = false;
      self.boundUnDeviceId = "";
    },
    handleBatchEnabledFace() {
      //批量人脸授权
      var self = this;
      self.loading = true;

      let boundDeviceId = self.boundDeviceId;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });
      var formData = new FormData();

      formData.append("ids", idList);

      personInfoApi.enabledFaceList(idList).then(function (response) {
        var jsonData = response.data;
        self.loading = false;
        if (jsonData.result) {
          self.changePage(self.pageIndex);
          self.$message({
            type: "success",
            message: "授权成功!",
          });
        } else {
          self.$message({
            type: "warning",
            message: jsonData.message,
          });
        }
      });
    },
    bindWechat(record) {
      this.bindWechatVisible = true;
      this.bindWechatLoading = true;
      this.bindWechatUrl = "";

      personInfoApi
        .bindWechat(record.id + "," + record.phone)
        .then((response) => {
          var jsonData = response.data;
          this.bindWechatLoading = false;

          if (jsonData.result) {
            this.bindWechatUrl = jsonData.data;
          } else {
            this.$message.warning(jsonData.message);
          }
        });
    },
    unbindWechat(record) {
      var self = this;
      this.$confirm("是否确认微信解绑？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        self.loading = true;
        personInfoApi
          .unbindWechat(record.id)
          .then(function (response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "解绑成功!",
              });
            }
          })
          .catch(() => {
            self.loading = false;
          });
      });
    },
    showLifeRecord(record) {
      this.showRecordModal = true;
      this.businessKey = record.id;
    },
    recordModalClose(refreshed) {
      this.showRecordModal = false;
    },
    handleClearFaceImg(record) {
      var self = this;
      self
        .$confirm("是否确认清除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          self.loading = true;
          personInfoApi.clearFaceImg(record.id).then(function (response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              self.$message({
                type: "success",
                message: "清除成功!",
              });
            } else {
              self.$message({
                showClose: true,
                dangerouslyUseHTMLString: true,
                message:
                  "发生错误" +
                  `,<a href="${jsonData.message}" target="_blank">点击下载错误信息</a>&nbsp;`,
                duration: 30000,
              });
            }
          });
        })
        .catch(() => {
          self.loading = false;
        });
    },
    handleBatchClearFaceImg() {
      var self = this;
      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认清除设备上的照片？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        self.loading = true;
        personInfoApi
          .batchClearFaceImg(idList)
          .then(function (response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "清除成功!",
              });
            }
          })
          .catch(() => {
            self.loading = false;
          });
      });
    },
    bindRole(record) {
      //绑定设备
      this.personId = record.id;
      this.showRoleModal = true;
    },
    updateHealthyCode(record) {
      var self = this;
      self.loading = true;

      personInfoApi.updateHealthyCode(record.id).then((response) => {
        self.loading = false;

        var jsonData = response.data;

        if (jsonData.result) {
          self.$message.success("更新健康码状态成功!");
          self.changePage(self.pageIndex);
        } else {
          self.$message.success("更新健康码状态失败!");
        }
      });
    },
    handleDelHkPerson(record) {
      var self = this;

      this.$confirm("是否确认删除海康云眸社区关联的用户？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        self.loading = true;
        personInfoApi
          .deleteHkPerson(record.id)
          .then(function (response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          })
          .catch(() => {
            self.loading = false;
          });
      });
    },
    handleTemporary(record) {
      this.showTemporaryModal = true;
    },
    onTemporaryModalClose(refreshed) {
      this.showTemporaryModal = false;
    },
    downloadZip() {
      var self = this;

      var parentId = self.queryModel.parentId;
      var subordinate = self.queryModel.subordinate;

      personInfoApi.downloadZip(parentId, subordinate);
    },
  },
  async mounted() {
    this.changePage(1);
    //self.tableHeight = window.innerHeight - self.$refs.queryForm.$el.offsetTop - 100;
    window.addEventListener("resize", this.adjustTableHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.adjustTableHeight);
  },
  components: {
    //"personInfo-detail": PersonInfoDetail,
    "personInfo-detailTabs": PersonInfoDetailTabs,
    "personDeviceRelation-list": PersonDeviceRelationList,
    "personDeviceRelation-BoundList": PersonDeviceRelationBoundList,
    "el-select-tree": SelectTree,
    "lifeRecord-list": LifeRecordList,
    "personPopedom-list": PersonPopedomList,
    "personInfo-temporary": PersonInfoTemporary,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
  background-color: white;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}

.sticky-panel {
  background-color: #fff;
}
</style>