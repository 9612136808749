
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    style="text-align: left"
    @close="closeDialog"
    width="900px"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
  >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基础信息" name="basic">
        <personInfo-detailBasic
          ref="PersonInfoDetailBasic"
          :businessKey="businessKey"
          :title="title"
          :companyResult="companyResult"
          :companyPosition1="companyPosition1"
          :companyPosition2="companyPosition2"
          :companyPosition3="companyPosition3"
          :companyPosition4="companyPosition4"
          :companyPosition5="companyPosition5"
          :tabName="tabName"
          @close="onDetailModalClose"
        ></personInfo-detailBasic>
      </el-tab-pane>
      <el-tab-pane label="账号设置" name="setting">
        <personInfo-detailSetting
          ref="PersonInfoDetailSetting"
          :businessKey="businessKey"
          :companyResult="companyResult"
          :companyPosition1="companyPosition1"
          :companyPosition2="companyPosition2"
          :companyPosition3="companyPosition3"
          :companyPosition4="companyPosition4"
          :companyPosition5="companyPosition5"
          @close="onDetailModalClose"
        ></personInfo-detailSetting>
      </el-tab-pane>
      <el-tab-pane label="关联设备" name="bindDevice" v-if="showType">
        <personInfo-detailBindDevice
          ref="PersonInfoDetailBindDevice"
          :personId="businessKey"
          @close="onDetailModalClose"
        ></personInfo-detailBindDevice>
      </el-tab-pane>
      <el-tab-pane label="已关联设备" name="boundDevice" v-if="showType">
        <personInfo-detailBoundDevice
          ref="PersonInfoDetailBoundDevice"
          :personId="businessKey"
          @close="onDetailModalClose"
        ></personInfo-detailBoundDevice
      ></el-tab-pane>
      <el-tab-pane label="机构身份管理" name="bindRole" v-if="showType">
        <personInfo-detailBindRole
          ref="PersonInfoDetailBindRole"
          :personId="businessKey"
          @close="onDetailModalClose"
        ></personInfo-detailBindRole>
      </el-tab-pane>
      <el-tab-pane label="卡片信息" name="card" v-if="showType">
        <personInfo-detailCard
          ref="PersonInfoDetailCard"
          :personId="businessKey"
          @close="onDetailModalClose"
        ></personInfo-detailCard
      ></el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import PersonInfoDetailBasic from "./personInfo-detailBasic";
import PersonInfoDetailSetting from "./personInfo-detailSetting";
import PersonInfoDetailBindRole from "./personInfo-detailBindRole";
import PersonInfoDetailBindDevice from "./personInfo-detailBindDevice";
import PersonInfoDetailBoundDevice from "./personInfo-detailBoundDevice";
import PersonInfoDetailCard from "./personInfo-detailCard";

import { getToken } from "@/utils/auth"; // get token from cookie

export default {
  props: [
    "businessKey",
    "title",
    "companyResult",
    "companyPosition1",
    "companyPosition2",
    "companyPosition3",
    "companyPosition4",
    "companyPosition5",
  ],

  data() {
    return {
      formModel: {},
      showDialog: true,
      loading: false,
      activeName: "basic",
      showType: false,
      tabName: "",
    };
  },
  methods: {
    handleClick(data) {
      if (data.name == "basic") {
        this.$refs.PersonInfoDetailBasic.loadThis();
      } else if (data.name == "setting") {
        this.$refs.PersonInfoDetailSetting.loadThis();
      } else if (data.name == "bindDevice") {
        this.$refs.PersonInfoDetailBindDevice.loadThis();
      } else if (data.name == "boundDevice") {
        this.$refs.PersonInfoDetailBoundDevice.loadThis();
      } else if (data.name == "bindRole") {
        this.$refs.PersonInfoDetailBindRole.loadThis();
      } else if (data.name == "card") {
        this.$refs.PersonInfoDetailCard.loadThis();
      }
    },
    closeDialog() {
      this.$emit("close", false);
    },
    onDetailModalClose(refreshed) {
      this.$emit("close", refreshed);
    },
  },
  mounted: function () {
    if (this.businessKey != "" && this.businessKey != null) {
      this.showType = true;
    }
  },
  components: {
    "personInfo-detailBasic": PersonInfoDetailBasic,
    "personInfo-detailSetting": PersonInfoDetailSetting,
    "personInfo-detailBindRole": PersonInfoDetailBindRole,
    "personInfo-detailBindDevice": PersonInfoDetailBindDevice,
    "personInfo-detailBoundDevice": PersonInfoDetailBoundDevice,
    "personInfo-detailCard": PersonInfoDetailCard,
  },
};
</script>