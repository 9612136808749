<template>
  <div class="user-panel" v-loading="loading">
    <el-form
      ref="form"
      :model="formModel"
      :rules="ruleValidate"
      :label-width="'120px'"
    >
      <el-row
        v-for="(card, index) in cardsList"
        v-bind:item="card"
        v-bind:index="index"
        v-bind:key="index"
      >
        <el-col :span="24">
          <el-form-item label="卡号" prop="cardNumber">
            <el-row>
              <el-col :span="14">
                <el-input-number
                  placeholder="请输入卡号"
                  style="width: 90%; text-align: left"
                  v-model="card.cardNumber"
                  :controls="false"
                >
                </el-input-number>
              </el-col>
              <el-col :span="4">
                <el-button
                  v-if="card.isSync"
                  class="el-icon-delete"
                  @click="delHivCard(card)"
                  >删除云端</el-button
                >
                <el-button
                  v-else
                  class="el-icon-upload"
                  @click="uploadHivCard(card)"
                  >上传云端</el-button
                >
              </el-col>
              <el-col :span="3">
                <el-button disabled class="el-icon-postcard">读 取</el-button>
              </el-col>
              <el-col :span="3">
                <el-button class="el-icon-delete" @click="delCard(index)"
                  >删 除</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-button
            style="width: 100%"
            @click="addCard"
            :loading="submitting"
            :disabled="addDisabled"
            class="el-icon-plus"
            >添加 {{ cardsNum }}/3</el-button
          >
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <span></span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <span
            style="text-align: right; display: block; margin-top: 100px"
            class="dialog-footer"
          >
            <el-button @click="closeDialog">取 消</el-button>
            <el-button
              type="primary"
              @click="handleSubmit"
              :loading="submitting"
              >确 定</el-button
            >
          </span>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import Constant from "@/constant";
import communityCardApi from "@/api/base/communityCard";

import { getToken } from "@/utils/auth"; // get token from cookie

export default {
  props: ["personId"],
  data() {
    return {
      formModel: {},
      ruleValidate: {},
      showDialog: true,
      loading: false,
      submitting: false,
      cardsList: [],
      cardsNum: 0,
      addDisabled: false,
    };
  },
  methods: {
    loadThis() {
      var self = this;
      var formData = new FormData();
      formData.append("personId", self.personId);

      communityCardApi.listByPersonId(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          self.cardsList = jsonData.data;
          self.cardsNum = self.cardsList.length;
          if (self.cardsList.length >= 3) {
            self.addDisabled = true;
          } else {
            self.addDisabled = false;
          }
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    closeDialog() {
      this.$emit("close", false);
    },
    addCard() {
      let newColumn = {
        id: "",
        cardNumber: undefined,
      };
      this.cardsList.push(newColumn);

      this.cardsNum = this.cardsList.length;
      if (this.cardsList.length >= 3) {
        this.addDisabled = true;
      } else {
        this.addDisabled = false;
      }
    },
    delCard(index) {
      this.cardsList.splice(index, 1);
      this.cardsNum = this.cardsList.length;
      if (this.cardsList.length >= 3) {
        this.addDisabled = true;
      } else {
        this.addDisabled = false;
      }
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            self.formModel.personId = self.personId;
            self.formModel.communityCardList = self.cardsList;
            return communityCardApi.addPersonCard(self.formModel);
          })().then(function (response) {
            var jsonData = response.data;
            self.loadThis();
            if (jsonData.result) {
              self.cardsList = jsonData.data;
              self.cardsNum = self.cardsList.length;

              if (self.cardsList.length >= 3) {
                self.addDisabled = true;
              } else {
                self.addDisabled = false;
              }
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              //self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    },
    delHivCard(card) {
      var self = this;
      var formData = new FormData();
      formData.append("personId", self.personId);
      formData.append("cardId", card.id);
      formData.append("cardNumber", card.cardNumber);

      communityCardApi.delHivCard(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          self.cardsList = jsonData.data;
          self.cardsNum = self.cardsList.length;
          if (self.cardsList.length >= 3) {
            self.addDisabled = true;
          } else {
            self.addDisabled = false;
          }
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    uploadHivCard(card) {
      var self = this;
      var formData = new FormData();
      formData.append("personId", self.personId);
      formData.append("cardId", card.id);
      formData.append("cardNumber", card.cardNumber);

      communityCardApi.uploadHivCard(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          self.cardsList = jsonData.data;
          self.cardsNum = self.cardsList.length;
          if (self.cardsList.length >= 3) {
            self.addDisabled = true;
          } else {
            self.addDisabled = false;
          }
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
  },
  mounted: function () {},
};
</script>